import { PropsWithChildren } from 'react';
import { Box } from '@wishket/design-system';
import { twMerge } from 'tailwind-merge';

interface WithSkeletonProps extends PropsWithChildren {
  isFit?: boolean;
  isLoading?: boolean;
  className?: string;
}

//TODO: WithSkeleton으로 감싼 컴포넌트가 2번 렌더링되는 현상이 있음
const WithSkeleton = ({
  isFit,
  isLoading,
  className,
  children,
}: WithSkeletonProps) => {
  return isLoading ? (
    <Box
      className={twMerge(
        'h-full w-full animate-pulse rounded-lg bg-gray-200',
        isFit && 'max-w-fit',
        className
      )}
      data-testid="with-skeleton"
    >
      <Box className="invisible">{children}</Box>
    </Box>
  ) : (
    children
  );
};

export default WithSkeleton;
