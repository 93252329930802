import React from 'react';

import { IconPartProps } from '../Icon.types';

const WishketNaver: React.FC<IconPartProps> = ({ className, role, testId }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role={role}
      data-testid={testId}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <text
          fontFamily="HelveticaNeue-Bold, Helvetica Neue"
          fontSize="15"
          fontWeight="bold"
          fill="#9E9E9E"
        >
          <tspan x="2.8" y="13.2">
            N
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default WishketNaver;
