import { Box, Typography } from '@wishket/design-system';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface ContentsContainerProps extends PropsWithChildren {
  title?: string;
  testId?: string;
  isDivide?: boolean;
}

const ContentsContainerTitle = ({
  title,
  isDivide,
}: ContentsContainerProps) => {
  return isDivide ? (
    <Box className="mb-8 hidden border-b border-w-gray-200 px-8 py-6 desktop:block">
      <Typography variant="title3" className="text-w-gray-900">
        {title}
      </Typography>
    </Box>
  ) : (
    <Typography
      variant="title3"
      className="mb-8 hidden text-w-gray-900 desktop:block"
    >
      {title}
    </Typography>
  );
};

const ContentsContainer = ({
  children,
  title,
  isDivide,
  testId,
}: ContentsContainerProps) => {
  return (
    <Box
      data-testid={testId}
      className={twMerge(
        'h-full w-full bg-w-white',
        'desktop:box-border desktop:flex desktop:flex-col desktop:rounded-2xl desktop:p-8 desktop:shadow-contents-container',
        isDivide && 'desktop:p-0'
      )}
    >
      {title && <ContentsContainerTitle title={title} isDivide={isDivide} />}
      {isDivide ? (
        <Box className="flex w-full flex-col gap-8 desktop:px-8 desktop:pb-8">
          {children}
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default ContentsContainer;
