import React from 'react';

import { IconPartProps } from '../Icon.types';

const SignUpComplete: React.FC<IconPartProps> = ({
  className,
  role,
  testId,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
      className={className}
      role={role}
      data-testid={testId}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.748 53.9748C82.231 51.5248 89.285 52.5388 90.504 56.2408C91.724 59.9418 86.646 64.9288 79.164 67.3808C71.681 69.8308 64.627 68.8158 63.408 65.1138C62.188 61.4118 67.266 56.4248 74.748 53.9748Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.401 31.6719C97.8951 30.6747 97.0141 29.9191 95.9516 29.5711C94.889 29.2231 93.7317 29.3112 92.734 29.8159C88.08 32.1659 84.48 35.1509 81.785 37.9759C82.099 33.2079 83.225 26.0379 86.196 20.3639C86.4525 19.8737 86.6099 19.3378 86.6592 18.7868C86.7084 18.2358 86.6485 17.6805 86.4829 17.1526C86.3173 16.6248 86.0492 16.1348 85.694 15.7107C85.3389 15.2865 84.9035 14.9366 84.413 14.6809C83.4221 14.1632 82.2663 14.0599 81.1993 14.3937C80.1323 14.7274 79.2413 15.4709 78.722 16.4609C78.67 16.5609 78.623 16.6629 78.571 16.7639C78.2577 16.0297 77.7426 15.3995 77.0854 14.9464C76.4283 14.4934 75.656 14.2361 74.8585 14.2045C74.0609 14.1729 73.2707 14.3682 72.5798 14.7678C71.8888 15.1674 71.3254 15.7548 70.955 16.4619C70.6721 17.0026 70.4004 17.549 70.14 18.1009C69.6591 17.6856 69.0907 17.3843 68.4771 17.2194C67.8635 17.0546 67.2206 17.0305 66.5964 17.149C65.9722 17.2674 65.3828 17.5254 64.8723 17.9035C64.3617 18.2817 63.9432 18.7703 63.648 19.3329C59.831 26.6209 58.509 35.3229 58.222 41.2229H58.22L58.217 41.3179C58.197 41.7269 58.183 42.1279 58.173 42.5239L58.188 42.5279C58.205 50.7049 62.822 55.6859 63.976 56.8039H63.975L64.301 57.5989L66.4 63.7879L67.509 67.0799C68.379 70.2699 73.715 71.5649 79.426 69.9739C85.136 68.3819 89.06 64.5069 88.19 61.3169L86.009 55.0219L85.815 54.4619C85.836 54.4539 85.855 54.4359 85.876 54.4259C86.747 53.5489 87.28 52.7729 87.608 52.1649C87.778 51.7899 87.916 51.4349 88.01 51.1629C88.551 49.5909 88.459 47.9359 88.496 46.3049C88.502 46.0669 88.519 45.8379 88.535 45.6069C88.662 44.6039 89.173 42.6669 91.161 40.7999C92.8301 39.4606 94.6333 38.2977 96.542 37.3299C97.0358 37.0808 97.4758 36.7367 97.8365 36.3174C98.1973 35.8981 98.4719 35.4118 98.6446 34.8863C98.8172 34.3608 98.8846 33.8064 98.8428 33.2548C98.801 32.7033 98.6509 32.1654 98.401 31.6719Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.182 54.1059C84.605 55.7809 81.748 57.5929 78.735 58.4329M81.785 37.9759C81.785 37.9759 72.147 37.9759 69.532 47.4999M78.722 16.4609C75.429 22.7489 73.989 30.4539 73.472 35.8549M70.955 16.4609C67.665 22.7419 66.225 30.4389 65.707 35.8359"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.1641 67.3798C71.6811 69.8298 64.6271 68.8158 63.4081 65.1138L66.0001 72.9838C67.2191 76.6848 74.2741 77.6988 81.7561 75.2488C89.2391 72.7988 94.3161 67.8108 93.0961 64.1098L90.5041 56.2408C91.7241 59.9428 86.6461 64.9298 79.1641 67.3798ZM44.5941 53.9748C37.1121 51.5248 30.0571 52.5388 28.8381 56.2408C27.6181 59.9418 32.6961 64.9288 40.1781 67.3808C47.6611 69.8308 54.7151 68.8158 55.9341 65.1138C57.1541 61.4118 52.0761 56.4248 44.5941 53.9748Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.316 3.41504H57.54L59.671 9.38704L61.801 3.41504H60.316Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.28 6.88306L67.798 6.45506C67.778 6.43506 67.565 6.24706 67.318 6.02506L66.21 5.04006L63.8 10.9061L69.386 7.87006L68.279 6.88406L68.28 6.88306ZM51.063 6.88306L51.543 6.45506C51.563 6.43506 51.776 6.24706 52.025 6.02506L53.132 5.03906L55.542 10.9051L49.955 7.86906L51.063 6.88306Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.764 42.5238C60.761 42.3858 60.753 42.2408 60.749 42.1008C60.761 41.4868 60.767 40.8318 60.762 40.1248C60.732 34.8558 60.149 27.1898 57.585 20.1828C57.1394 18.9694 56.2306 17.9824 55.0581 17.4383C53.8856 16.8943 52.5452 16.8376 51.331 17.2808C50.6843 17.517 50.0955 17.8886 49.604 18.3708C49.514 18.1128 49.433 17.8538 49.339 17.5988C48.8945 16.3848 47.986 15.3971 46.8134 14.8529C45.6408 14.3087 44.3 14.2525 43.086 14.6968C42.512 14.9068 42.004 15.2168 41.568 15.5948C40.8468 14.9689 39.9578 14.5684 39.0112 14.443C38.0646 14.3176 37.102 14.4728 36.2428 14.8893C35.3836 15.3059 34.6655 15.9654 34.1775 16.7862C33.6896 17.607 33.4533 18.5529 33.498 19.5068C33.515 20.0098 33.608 20.5198 33.79 21.0188C35.109 24.6208 35.774 28.5688 36.097 32.0548C36.096 38.6098 35.589 43.1948 35.056 46.2108L32.496 56.2708L30.746 61.3168C30.688 61.5383 30.6521 61.7651 30.639 61.9938C30.374 65.0538 34.157 68.5538 39.511 70.0458C45.221 71.6368 50.558 70.3418 51.428 67.1518L52.537 63.8598L54.636 57.6708L54.962 56.8758H54.961C55.025 56.8138 55.103 56.7358 55.187 56.6508C55.512 56.3138 56.161 55.5908 56.909 54.5038C58.6063 52.1317 59.778 49.4248 60.346 46.5638C60.36 46.4998 60.378 46.4388 60.392 46.3738C60.524 45.6578 60.622 44.9078 60.682 44.1278L60.697 43.9338C60.727 43.4978 60.747 43.0558 60.749 42.5988L60.764 42.5958L60.761 42.5258L60.764 42.5238Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.764 42.5238C60.761 42.3858 60.753 42.2408 60.749 42.1008C60.761 41.4868 60.767 40.8318 60.762 40.1248C60.732 34.8558 60.149 27.1898 57.585 20.1828C57.1394 18.9694 56.2306 17.9824 55.0581 17.4383C53.8856 16.8943 52.5452 16.8376 51.331 17.2808C50.6843 17.517 50.0955 17.8886 49.604 18.3708C49.514 18.1128 49.433 17.8538 49.339 17.5988C48.8945 16.3848 47.986 15.3971 46.8134 14.8529C45.6408 14.3087 44.3 14.2525 43.086 14.6968C42.512 14.9068 42.004 15.2168 41.568 15.5948C40.8468 14.9689 39.9578 14.5684 39.0112 14.443C38.0646 14.3176 37.102 14.4728 36.2428 14.8893C35.3836 15.3059 34.6655 15.9654 34.1775 16.7862C33.6896 17.607 33.4533 18.5529 33.498 19.5068C33.515 20.0098 33.608 20.5198 33.79 21.0188C35.109 24.6208 35.774 28.5688 36.097 32.0548C36.097 32.2648 36.095 32.4658 36.094 32.6708C35.17 31.6628 32.327 29.2458 31.224 28.2768C30.3838 27.5391 29.2852 27.165 28.1694 27.2367C27.0537 27.3083 26.0119 27.8198 25.273 28.6588C24.9072 29.0737 24.6269 29.5567 24.448 30.0801C24.2691 30.6035 24.1952 31.1571 24.2305 31.7091C24.2658 32.2611 24.4096 32.8007 24.6537 33.2971C24.8978 33.7934 25.2373 34.2368 25.653 34.6018C26.783 35.5928 29.617 38.0458 30.51 39.0858C31.453 40.2528 34.1 43.9398 34.395 48.8088L32.495 56.2698L30.747 61.3168C30.6886 61.5383 30.6525 61.7651 30.639 61.9938C30.374 65.0538 34.157 68.5538 39.511 70.0458C45.221 71.6368 50.558 70.3418 51.428 67.1518L52.537 63.8598L54.636 57.6708L54.962 56.8758H54.961C55.025 56.8138 55.103 56.7358 55.187 56.6508C55.512 56.3138 56.161 55.5908 56.909 54.5038C58.6063 52.1317 59.778 49.4248 60.346 46.5638C60.36 46.4998 60.378 46.4388 60.392 46.3738C60.524 45.6578 60.622 44.9078 60.682 44.1278L60.697 43.9338C60.727 43.4978 60.747 43.0558 60.749 42.5988L60.764 42.5958L60.761 42.5258L60.764 42.5238Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.7249 35.8358C42.5829 31.6698 41.9469 26.7698 40.1799 21.0188C39.5879 19.0908 40.0499 15.7298 43.0859 14.6968M50.6839 35.8358C50.5639 32.3418 50.0969 28.3328 48.9079 23.7388C48.9079 23.7388 47.3679 18.7218 51.3299 17.2808M36.0929 32.6708C36.0929 32.6708 36.3059 34.1128 36.1359 37.1248C36.0954 37.8352 36.0403 38.5446 35.9709 39.2528"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.178 67.3802C47.66 69.8302 54.715 68.8162 55.934 65.1142L53.342 72.9842C52.123 76.6852 45.068 77.6992 37.586 75.2492C30.103 72.7992 25.026 67.8112 26.246 64.1102L28.838 56.2412C27.618 59.9432 32.696 64.9302 40.178 67.3802Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignUpComplete;
