import React from 'react';

import { IconPartProps } from '../Icon.types';

const CheckCircle: React.FC<IconPartProps> = ({ className, role, testId }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={testId}
      role={role}
    >
      <g fill="none" fillRule="evenodd" stroke="#30B891">
        <circle cx="9" cy="9" r="8.5" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="M5.625 8.918L8.138 11.25 12.375 6.75"
        />
      </g>
    </svg>
  );
};

export default CheckCircle;
