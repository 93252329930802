import React from 'react';

import { IconPartProps } from '../Icon.types';

const WarningCircle: React.FC<IconPartProps> = ({
  className,
  role,
  testId,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      data-testid={testId}
      role={role}
    >
      <g fill="none" fillRule="evenodd" transform="rotate(-180 9 9)">
        <circle cx="9" cy="9" r="8.5" stroke="#FF8547" />
        <path
          fill="#FF8547"
          d="M8.998 6.063C8.44 6.059 7.996 5.606 8 5.05c.005-.532.463-.987.996-.987.547 0 1.01.466 1.004 1.01-.006.543-.462.992-1.002.99"
        />
        <path
          stroke="#FF8547"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="M9 12.8L9 8.2"
        />
      </g>
    </svg>
  );
};

export default WarningCircle;
