import React from 'react';

import { IconPartProps } from '../Icon.types';

const MailSend: React.FC<IconPartProps> = ({ className, testId, role }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
      className={className}
      data-testid={testId}
      role={role}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.47 40.357L54.473 69.5L28.249 50.704L68.246 21.562L94.47 40.357Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.4697 40.3569L59.6927 44.3369L54.4727 69.4989L94.4697 40.3569Z"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.25 50.704L63.026 46.725L68.246 21.562L28.25 50.704Z"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.1945 25.2541C50.9895 16.8541 46.3645 10.8621 43.8644 11.8711C42.2385 12.5281 41.9204 15.9941 42.7744 20.6441C41.2524 19.9141 39.9715 19.7851 39.1575 20.4201C37.1275 22.0051 38.7425 27.7481 42.7475 33.5311C42.0645 33.7761 41.5425 34.1611 41.2925 34.7171C40.4005 36.6971 43.0185 39.8921 47.1385 41.8571C49.4985 42.9801 54.7765 44.7191 56.4525 41.6471C57.4265 39.8591 57.0704 36.9831 56.8155 35.0611C56.3695 31.7011 55.3955 28.4051 54.1945 25.2541Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.7695 20.6382C42.7695 20.6382 46.7775 22.0682 51.1555 28.4132M42.7695 33.5192C42.7695 33.5192 45.7395 32.4292 50.3995 34.6882"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.5352 36.4919C77.0362 34.9489 73.2172 36.3769 70.0062 39.6799C69.3837 40.3255 68.8133 41.0193 68.3002 41.7549C67.8229 41.4879 67.323 41.2637 66.8062 41.0849C63.5862 39.9949 60.4402 40.7929 59.7782 42.8659C59.5022 43.7279 59.7222 44.6719 60.2802 45.5629C57.3572 46.3539 55.3702 47.6609 55.5142 48.8859C55.7042 50.4789 59.4212 51.3239 63.8202 50.7739C67.7372 50.2839 71.3542 48.6009 74.4002 46.0269C76.3242 44.3989 81.2112 39.2449 78.5352 36.4919Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.2532 41.7319C68.2532 41.7319 70.1882 42.5229 71.2842 44.8359M60.2432 45.5659C60.2432 45.5659 62.5252 44.9229 65.5912 44.8379"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5373 9.86012C23.8573 9.86012 23.2273 10.1661 22.5343 10.0751C21.7703 9.97512 21.2703 9.41713 20.6103 9.09013C19.9331 8.75595 19.1885 8.58084 18.4333 8.57812C17.6785 8.58106 16.9343 8.75617 16.2573 9.09013C15.5973 9.41713 15.0973 9.97512 14.3333 10.0751C13.6413 10.1651 13.0103 9.86012 12.3303 9.86012C10.3233 9.86012 8.69531 11.4751 8.69531 13.4671C8.69531 15.4591 10.3233 17.0741 12.3303 17.0741C13.0103 17.0741 13.6413 16.7681 14.3333 16.8591C15.0973 16.9591 15.5973 17.5171 16.2573 17.8441C16.9343 18.1781 17.6785 18.3532 18.4333 18.3561C19.1885 18.3534 19.9331 18.1783 20.6103 17.8441C21.2703 17.5171 21.7703 16.9591 22.5343 16.8591C23.2263 16.7681 23.8573 17.0741 24.5373 17.0741C26.5443 17.0741 28.1723 15.4591 28.1723 13.4671C28.1723 11.4751 26.5443 9.86012 24.5373 9.86012ZM108.06 64.0821C107.38 64.0821 106.75 64.3881 106.058 64.2971C105.293 64.1971 104.793 63.6391 104.133 63.3121C103.456 62.9782 102.712 62.8031 101.957 62.8001C101.202 62.803 100.458 62.9781 99.7813 63.3121C99.1213 63.6391 98.6213 64.1971 97.8563 64.2971C97.1653 64.3871 96.5343 64.0821 95.8543 64.0821C93.8473 64.0821 92.2193 65.6971 92.2193 67.6891C92.2193 69.6811 93.8473 71.2961 95.8543 71.2961C96.5343 71.2961 97.1643 70.9901 97.8563 71.0811C98.6213 71.1811 99.1213 71.7391 99.7813 72.0661C100.458 72.4 101.202 72.5751 101.956 72.5781C102.711 72.5753 103.456 72.4002 104.133 72.0661C104.793 71.7391 105.293 71.1811 106.058 71.0811C106.749 70.9901 107.38 71.2961 108.06 71.2961C110.067 71.2961 111.695 69.6811 111.695 67.6891C111.695 65.6971 110.067 64.0821 108.06 64.0821Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailSend;
