import React from 'react';

import { IconPartProps } from '../Icon.types';

const InputInfo: React.FC<IconPartProps> = ({ className, testId, role }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={testId}
      role={role}
    >
      <circle
        cx="8"
        cy="8"
        r="6.4"
        stroke="#9E9E9E"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.0625V7.5625"
        stroke="#9E9E9E"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99779 5.8125C7.51041 5.80988 7.12104 5.4135 7.12454 4.92612C7.12891 4.46062 7.52966 4.0625 7.99604 4.0625C8.47466 4.0625 8.88066 4.47025 8.87454 4.94713C8.86929 5.42225 8.47029 5.81512 7.99779 5.8125Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default InputInfo;
