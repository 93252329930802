import React from 'react';

import { IconPartProps } from '../Icon.types';

const LogIn: React.FC<IconPartProps> = ({ className, testId, role }) => {
  return (
    <svg
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={testId}
      role={role}
    >
      <g id="Illust_Login">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5373 9.85988C23.8573 9.85988 23.2273 10.1659 22.5343 10.0749C21.7703 9.97488 21.2703 9.41688 20.6103 9.08988C19.9331 8.75571 19.1885 8.58059 18.4333 8.57788C17.6785 8.58081 16.9343 8.75592 16.2573 9.08988C15.5973 9.41688 15.0973 9.97488 14.3333 10.0749C13.6413 10.1649 13.0103 9.85988 12.3303 9.85988C10.3233 9.85988 8.69531 11.4749 8.69531 13.4669C8.69531 15.4589 10.3233 17.0739 12.3303 17.0739C13.0103 17.0739 13.6413 16.7679 14.3333 16.8589C15.0973 16.9589 15.5973 17.5169 16.2573 17.8439C16.9343 18.1778 17.6785 18.3529 18.4333 18.3559C19.1885 18.3532 19.9331 18.1781 20.6103 17.8439C21.2703 17.5169 21.7703 16.9589 22.5343 16.8589C23.2263 16.7679 23.8573 17.0739 24.5373 17.0739C26.5443 17.0739 28.1723 15.4589 28.1723 13.4669C28.1723 11.4749 26.5443 9.85988 24.5373 9.85988ZM108.06 64.0819C107.38 64.0819 106.75 64.3879 106.058 64.2969C105.293 64.1969 104.793 63.6389 104.133 63.3119C103.456 62.978 102.712 62.8028 101.957 62.7999C101.202 62.8027 100.458 62.9778 99.7813 63.3119C99.1213 63.6389 98.6213 64.1969 97.8563 64.2969C97.1653 64.3869 96.5343 64.0819 95.8543 64.0819C93.8473 64.0819 92.2193 65.6969 92.2193 67.6889C92.2193 69.6809 93.8473 71.2959 95.8543 71.2959C96.5343 71.2959 97.1643 70.9899 97.8563 71.0809C98.6213 71.1809 99.1213 71.7389 99.7813 72.0659C100.458 72.3998 101.202 72.5749 101.956 72.5779C102.711 72.5751 103.456 72.4 104.133 72.0659C104.793 71.7389 105.293 71.1809 106.058 71.0809C106.749 70.9899 107.38 71.2959 108.06 71.2959C110.067 71.2959 111.695 69.6809 111.695 67.6889C111.695 65.6969 110.067 64.0819 108.06 64.0819ZM32.1163 58.6439L36.4983 36.5559C37.1723 33.1539 40.6203 30.9659 44.1583 31.6959L76.6783 38.3999C80.2173 39.1299 82.5603 42.5099 81.8853 45.9129L77.5033 67.9999C76.8283 71.4009 73.3803 73.5879 69.8413 72.8579L37.3243 66.1549C33.7843 65.4249 31.4413 62.0449 32.1163 58.6419V58.6439Z"
          fill="white"
          stroke="#212121"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.942 47.1701C51.572 49.0281 49.807 50.2301 47.999 49.8581C46.189 49.4881 45.024 47.6811 45.393 45.8241C45.763 43.9681 47.528 42.7641 49.336 43.1361C51.145 43.5071 52.311 45.3131 51.942 47.1701ZM50.403 53.0271L49.171 52.7741L47.059 52.3391L45.806 52.0821L44.573 51.8281C41.963 51.2921 41.379 53.3781 41.288 53.8021L40.75 56.5061L46.014 57.5891L52.137 58.8481L52.675 56.1441C52.754 55.7171 53.013 53.5641 50.403 53.0271Z"
          fill="#5DB6D4"
          stroke="#212121"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M61.2315 46.9319L73.9625 49.5499M60.0435 52.9039L72.7745 55.5219M58.8555 58.8759L71.5865 61.4939"
          stroke="#212121"
          strokeWidth="1.4"
        />
        <path
          id="Vector_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.481 7.42203C66.449 7.41403 66.418 7.40303 66.385 7.39603C66.381 7.39603 66.376 7.39603 66.371 7.39403C66.366 7.39403 66.362 7.39103 66.357 7.39003C66.325 7.38403 66.292 7.38203 66.259 7.37503C56.313 5.39703 46.755 11.407 44.876 20.856L44.883 20.857C46.574 19.147 49.046 18.297 51.559 18.814C54.07 19.33 56.013 21.09 56.918 23.332L57.053 23.36C58.718 21.678 61.133 20.83 63.605 21.3C66.062 21.844 67.963 23.58 68.854 25.786L68.99 25.814C70.68 24.107 73.143 23.252 75.654 23.769C78.167 24.286 80.12 26.045 81.024 28.289L81.032 28.29C82.911 18.842 76.402 9.53003 66.481 7.42203Z"
          fill="#5DB6D4"
          stroke="#212121"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M66.481 7.4219C66.481 7.4219 71.414 10.8649 70.133 20.8129M60.119 12.8399C59.276 14.2649 58.495 16.0529 57.887 18.2959M66.37 7.3999C66.37 7.3999 64.26 7.8399 62.005 10.2809M44.876 20.8559L53.496 33.6219M56.832 26.1629L58.107 34.5649M67.987 28.4569L63.601 35.6949M81.032 28.2909L68.21 36.6469"
          stroke="#212121"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default LogIn;
