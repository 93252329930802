import React from 'react';

import { IconPartProps } from '../Icon.types';

const AccountLeave: React.FC<IconPartProps> = ({ className, testId, role }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
      className={className}
      data-testid={testId}
      role={role}
    >
      <path
        d="M46.2078 15.8181H76.3377C78.564 15.8181 80.3636 17.6259 80.3636 19.8624V64.1818H42.1818V19.8624C42.1818 17.6259 43.9815 15.8181 46.2078 15.8181Z"
        fill="#3BA3C7"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.1635 12.0018L43.1092 14.727C40.2333 15.1003 39.7087 17.1536 39.6364 18.1802V64.1818L57.9593 67.1969L64.1635 67.9995C65.5924 68.0182 67.6364 67.5142 67.6364 64.3783V15.623C67.6364 12.4871 65.5924 11.9645 64.1635 12.0018Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.4545 40H62.5454"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.3637 64.1819H88.0001"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M32 64.1819H39.6364"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AccountLeave;
