import { Box, LoadingIndicator, Typography } from '@wishket/design-system';

import Dialog from '../Dialog';

interface LoadingDialogProps {
  description?: string;
}

const LoadingDialog = ({ description }: LoadingDialogProps) => {
  return (
    <Dialog testId="loading-dialog">
      <Typography variant="body1">{description}</Typography>
      <Box className="relative h-8">
        {/* TODO: WDS변경 후 다른 형태의 로딩 인디케이터로 적용 */}
        <LoadingIndicator size="md" />
      </Box>
    </Dialog>
  );
};

export default LoadingDialog;
