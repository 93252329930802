import { Button, SystemIcon, Typography } from '@wishket/design-system';

import { SocialLoginProvider } from '@/shared/types';

interface SocialFormButtonProps {
  text: string;
  type: SocialLoginProvider;
  onClick?: () => void;
}

const SocialFormButton = ({ text, type, onClick }: SocialFormButtonProps) => {
  const generateIconType = (type: string) => {
    if (type === 'google') return 'social_google';
    if (type === 'facebook') return 'social_facebook';

    return 'social_google';
  };

  return (
    <Button
      className="h-[50px] w-full rounded-xl text-w-gray-600"
      onClick={onClick}
    >
      <SystemIcon
        name={generateIconType(type)}
        testId={`social-form-button-icon--${type}`}
      />
      <Typography variant="body1" className="w-full">
        {text}
      </Typography>
    </Button>
  );
};

export default SocialFormButton;
