import React from 'react';

import iconMap from './Icon.constants';
import { IconProps } from './Icon.types';

const Icon = ({ name, className, role, testId, size }: IconProps) => {
  const IncomingIcon = iconMap.get(name);

  return (
    IncomingIcon && (
      <IncomingIcon
        className={className}
        role={role}
        testId={testId}
        size={size}
      />
    )
  );
};

export default Icon;
