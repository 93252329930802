import React from 'react';

const Logout: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.17 40C71.7867 39.9993 71.4073 39.9232 71.0535 39.7759C70.6997 39.6286 70.3783 39.413 70.1078 39.1414C69.8373 38.8699 69.6229 38.5478 69.4769 38.1934C69.331 37.839 69.2562 37.4593 69.257 37.076V26.582C69.257 21.214 64.908 16.848 59.562 16.848C54.215 16.848 49.866 21.214 49.866 26.582V37.075C49.8668 37.4583 49.7921 37.838 49.6461 38.1924C49.5001 38.5468 49.2858 38.8689 49.0153 39.1404C48.7447 39.412 48.4234 39.6276 48.0695 39.7749C47.7157 39.9222 47.3363 39.9983 46.953 39.999C46.5698 39.9983 46.1904 39.9222 45.8367 39.7749C45.4829 39.6275 45.1616 39.4119 44.8911 39.1404C44.6207 38.8688 44.4064 38.5467 44.2606 38.1923C44.1147 37.8379 44.0401 37.4582 44.041 37.075V26.582C44.041 17.991 51.004 11 59.562 11C68.12 11 75.083 17.99 75.083 26.582V37.075C75.0838 37.4583 75.0091 37.838 74.8631 38.1924C74.7171 38.5468 74.5028 38.8689 74.2323 39.1404C73.9617 39.412 73.6404 39.6276 73.2865 39.7749C72.9327 39.9222 72.5533 39.9993 72.17 40Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.244 69.0001H44.88C39.996 69.0001 36 65.0041 36 60.1201V41.9391C36 37.0551 39.996 33.0591 44.88 33.0591H74.245C79.129 33.0591 83.125 37.0551 83.125 41.9391V60.1191C83.125 65.0041 79.129 68.9991 74.245 68.9991L74.244 69.0001Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9769 51.012C62.7019 50.374 63.1669 49.462 63.1669 48.437C63.1669 46.511 61.5529 44.948 59.5619 44.948C57.5709 44.948 55.9569 46.511 55.9569 48.437C55.9569 49.462 56.4209 50.374 57.1469 51.012L55.6089 57.11H63.5149L61.9769 51.012Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logout;
