import React from 'react';

import { IconPartProps } from '../Icon.types';

const Unplugged: React.FC<IconPartProps> = ({ className, testId, role }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="80"
      viewBox="0 0 120 80"
      fill="none"
      className={className}
      data-testid={testId}
      role={role}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.78 47.0927V32.9073C30.78 31.1481 31.5175 29.4609 32.8302 28.217C34.143 26.973 35.9235 26.2742 37.78 26.2742H49.67V53.7258H37.78C35.9235 53.7258 34.143 53.0269 32.8302 51.783C31.5175 50.539 30.78 48.8519 30.78 47.0927Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.6689 34.12V29.2115C49.6689 28.9708 49.8749 28.7756 50.1289 28.7756H60.0419C60.4295 28.7755 60.8133 28.8478 61.1714 28.9882C61.5295 29.1287 61.8549 29.3347 62.1289 29.5944C62.403 29.8541 62.6203 30.1624 62.7686 30.5017C62.9168 30.841 62.9931 31.2047 62.9929 31.572V31.7586C62.9931 32.1258 62.9169 32.4894 62.7687 32.8287C62.6205 33.1679 62.4032 33.4762 62.1293 33.7359C61.8553 33.9956 61.5301 34.2016 61.1721 34.3421C60.8141 34.4826 60.4304 34.555 60.0429 34.555H50.1289C50.0686 34.5551 50.0088 34.5439 49.953 34.5221C49.8972 34.5003 49.8465 34.4683 49.8037 34.4279C49.761 34.3875 49.7271 34.3395 49.704 34.2867C49.6809 34.2339 49.6689 34.1772 49.6689 34.12ZM49.6689 50.789V45.8796C49.6689 45.6398 49.8749 45.4437 50.1289 45.4437H60.0419C60.4296 45.4436 60.8135 45.5158 61.1716 45.6564C61.5298 45.7969 61.8552 46.003 62.1293 46.2628C62.4033 46.5226 62.6207 46.831 62.7689 47.1704C62.9171 47.5099 62.9932 47.8736 62.9929 48.241V48.4276C62.9929 49.169 62.6821 49.88 62.1289 50.4043C61.5757 50.9285 60.8253 51.223 60.0429 51.223H50.1289C50.0687 51.2232 50.009 51.212 49.9532 51.1903C49.8975 51.1685 49.8468 51.1366 49.8041 51.0963C49.7614 51.056 49.7275 51.0081 49.7043 50.9554C49.6811 50.9027 49.6691 50.8462 49.6689 50.789Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.6689 24V55.9991"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.78 40V32.027L22.291 33.9799V46.02L30.781 47.973L30.78 40ZM89.219 32.9073V47.0927C89.219 48.8519 88.4815 50.539 87.1687 51.783C85.8559 53.0269 84.0755 53.7258 82.219 53.7258H70.33V26.2742H82.22C84.0765 26.2742 85.857 26.973 87.1697 28.217C88.4825 29.4609 89.219 31.1481 89.219 32.9073Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.33 56.0001V24.001"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.219 39.9999V47.9729L97.709 46.0208V33.9789L89.219 32.0269V39.9999Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 36.147H22.29V43.8528H7.001M113 43.8528H97.709V36.147H112.999"
        fill="white"
      />
      <path
        d="M7 36.147H22.29V43.8528H7.001M113 43.8528H97.709V36.147H112.999"
        stroke="#212121"
        strokeWidth="1.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.316 6.41504H57.54L59.671 12.387L61.801 6.41504H60.316Z"
        fill="white"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.28 9.88306L67.798 9.45506C67.778 9.43506 67.565 9.24706 67.318 9.02506L66.21 8.04006L63.8 13.9061L69.386 10.8701L68.279 9.88406L68.28 9.88306ZM51.063 9.88306L51.543 9.45506C51.563 9.43506 51.776 9.24706 52.025 9.02506L53.132 8.03906L55.542 13.9051L49.955 10.8691L51.063 9.88306Z"
        fill="#5DB6D4"
        stroke="#212121"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Unplugged;
