import React from 'react';

import { IconPartProps } from '../Icon.types';

const WishketFacebook: React.FC<IconPartProps> = ({
  className,
  role,
  testId,
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role={role}
      data-testid={testId}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path
          d="M9.382 14V8.07h1.99l.298-2.311H9.382V4.284c0-.67.186-1.126 1.145-1.126h1.225V1.09A16.458 16.458 0 0 0 9.967 1C8.204 1 6.995 2.077 6.995 4.054V5.76H5V8.07h1.995V14h2.387z"
          fill="#9E9E9E"
        />
      </g>
    </svg>
  );
};

export default WishketFacebook;
