import {
  InputInfo,
  WishketFacebook,
  WishketNaver,
  WishketBlog,
  MailSend,
  AccountLeave,
  PasswordLock,
  CheckCircle,
  SignUpComplete,
  LogIn,
  WarningCircle,
  Unplugged,
} from './parts';

const iconMap = new Map([
  ['input_info', InputInfo],
  ['wishket_facebook', WishketFacebook],
  ['wishket_naver', WishketNaver],
  ['wishket_blog', WishketBlog],
  ['mail_send', MailSend],
  ['account_leave', AccountLeave],
  ['password_lock', PasswordLock],
  ['check_circle', CheckCircle],
  ['warning_circle', WarningCircle],
  ['sign_up_complete', SignUpComplete],
  ['login', LogIn],
  ['unplugged', Unplugged],
]);

export default iconMap;
